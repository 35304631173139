import Navigation from "./components/Navigation/Navigation";
import Dashboard from "./components/Dashboard/Dashboard";
import ProjectDetailsSection from "./components/ProjectDetailsSection/ProjectDetailsSection";
import Descargas from "./components/Descargas/Descargas";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className="overflow-hidden">
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Navigation />
              <ProjectDetailsSection />
              <Dashboard />
            </>
          }
        ></Route>
        <Route
          path="/descargas"
          element={
            <>
              <Navigation />
              <Descargas />
            </>
          }
        ></Route>
      </Routes>
    </div>
  );
}

export default function MainApp() {
  return (
    <Router>
      <App />
    </Router>
  );
}
