import logo from "../../images/logo.svg";
import { Link } from "react-router-dom";

export default function Navigation() {
  return (
    <div className="bg-blueRio flex justify-between items-center px-8 py-8">
      <div className="flex items-center">
        <Link to="/">
          <img src={logo} alt="sosac" className="w-72" />
        </Link>
      </div>
      <div>
        <Link to="/descargas" className="mr-4 text-white hover:text-gray-300">
          Descargas
        </Link>
      </div>
    </div>
  );
}
