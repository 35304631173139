export default function InvestigacionTable(props) {
  return (
    <div className="mt-5">
      <table>
        <tr>
          <th>Objeto de conservación</th>
          <th>Objetivos de conservación</th>
        </tr>
        <tr>
          <td>
            <p>Hábitat íntegro y servicios ambientales de calidad</p>
          </td>
          <td>
            <ul>
              <li>
                Asegurar fuentes de agua limpia en previsión de un caudal
                ecológico
              </li>
              <li>Detener el ingreso de contaminantes al agua y al suelo</li>
              <li>
                Determinar la capacidad de carga para diferentes actividades y
                dinámicas (vg. actividades de esparcimiento y apreciación del
                paisaje).
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>
            <p>Estructura y función hidráulica</p>
          </td>
          <td>
            <ul>
              <li>
                Reducir los riesgos asociados a fenómenos hidrometeorológicos.
              </li>
              <li>
                Identificar e implementar soluciones basadas en la naturaleza
                para recuperar la funcionalidad hidrológica por zona.
              </li>
              <li>Reducir la acumulación de sedimentos</li>
              <li>
                Acoplar trabajo en la cuenca alta y trabajo en el tramo urbano
              </li>
              <li>
                Multi-localizar las soluciones, descentralizar la función
                hidrológica para acoplarla a los ecosistemas locales: función y
                estructura distribuida.
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>
            <p>Comunidades vegetales nativas</p>
          </td>
          <td>
            <ul>
              <li>
                Promover una composición vegetal propia de la región y capaz de
                mejorar la calidad de los servicios ambientales locales.
              </li>
              <li>
                Controlar los brotes de vegetación invasora catalogada como
                prioridad de manejo en el país y en ecosistemas afines.
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>
            <p>Tejido social</p>
          </td>
          <td>
            <ul>
              <li>
                Considerar, valorar y promover el tejido social de las
                comunidades vecinas del río en relación con el territorio que
                habitan, con especial atención en los asentamientos autogestivos
                y en polígonos prioritarios para la igualdad e inclusión.
              </li>
              <li>
                Asegurar que la mejora del entorno ecológico en el espacio
                público proteja a la población local sin promover su
                desplazamiento.
              </li>
              <li>
                Valorar las capacidades locales y promover el cuidado
                comunitario e interinstitucional de los ecosistemas locales.
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>
            <p>Fauna sujeta a protección especial</p>
          </td>
          <td>
            <ul>
              <li>
                Asegurar hábitat propicio para la fauna sujeta a conservación.
              </li>
              <li>Asegurar la conectividad entre ANPs.</li>
              <li>
                Propiciar una sana coexistencia ciudadanía - vida silvestre.
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>
            <p>Identidad regia</p>
          </td>
          <td>
            <ul>
              <li>
                Consolidar al Río como un elemento vivo e icónico de la ciudad
                de Monterrey (múltiples territorios, una identidad compartida,
                un flujo, una cuenca).
              </li>
              <li>
                Valorar la diversidad de relaciones entre el río y sus vecinos
                humanos.
              </li>
            </ul>
          </td>
        </tr>
      </table>
    </div>
  );
}
