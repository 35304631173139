import { useState, useEffect } from "react";
import { supabaseClient } from "../../config/supabase";
import { getParamsForRpc } from "../../helpers/helpers";

const useFilteredTableData = (filters, rpcFunctionName) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  async function getTableData() {
    try {
      const params = getParamsForRpc(rpcFunctionName, filters);

      const { data: resultData, error } = await supabaseClient.rpc(
        rpcFunctionName,
        params
      );

      if (error) {
        setError(error);
        setData([]);
      } else {
        setData(resultData);
      }
    } catch (err) {
      setError(err);
      setData([]);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getTableData();
  }, [rpcFunctionName, filters]);

  return { data, loading, error };
};

export default useFilteredTableData;
