import { useState } from "react";
import Map from "../Map/Map";
import Filters from "../Filters/Filters.js";
import { FiltersContext } from "../../contexts/FiltersContext.js";
import Table from "../Table/Table.js";

export default function Dashboard() {
  const defaultCuenca = "media";

  const [filters, setFilters] = useState({
    municipio: "",
    rh: 1,
    cuenca: defaultCuenca,
    amenazada: 1,
    puntos: "biodiversidad",
  });

  const rpcName = (() => {
    switch (filters.puntos) {
      case "biodiversidad":
        return "get_biodiversidad_summary_with_filters";
      case "suelo":
        return "get_suelo_summary_with_filters";
      case "denue":
        return "get_denue_summary_with_filters";
      default:
        return "get_default_summary_with_filters";
    }
  })();

  return (
    <div className="max-w-full overflow-hidden p-2 m-4 md:p-10 md:m-10 box-border">
      <FiltersContext.Provider
        value={{
          filters,
          setFilters,
        }}
      >
        <Filters />
        <Map />
        <Table rpcName={rpcName} version={filters.puntos} />
      </FiltersContext.Provider>
    </div>
  );
}
