import { useState, useEffect } from "react";
import { supabaseClient } from "../config/supabase";

export function useGetPolygon(geoTableName) {
  const [polygon, setPolygon] = useState();
  const [error, setError] = useState();

  async function getPolygon() {
    let { data, error } = await supabaseClient.rpc(geoTableName);
    if (error) setError(error);
    else {
      setPolygon(data.features);
    }
  }

  useEffect(() => {
    getPolygon();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geoTableName]);

  if (error) {
    return error;
  } else {
    return polygon;
  }
}
