import React from "react";

const SueloTable = ({ data }) => {
  const grupoFuncionalRowSpans = {};
  let lastGrupoFuncional = "";

  data.forEach((row) => {
    const { grupo_funcional } = row;
    if (!grupoFuncionalRowSpans[grupo_funcional]) {
      grupoFuncionalRowSpans[grupo_funcional] = 0;
    }
    grupoFuncionalRowSpans[grupo_funcional] += 1;
  });

  const preparedData = data.map((row) => {
    const isNewGrupoFuncional = row.grupo_funcional !== lastGrupoFuncional;
    lastGrupoFuncional = row.grupo_funcional;

    if (isNewGrupoFuncional) {
      return {
        ...row,
        isFirstOccurrence: true,
        rowSpan: grupoFuncionalRowSpans[row.grupo_funcional],
      };
    } else {
      return {
        ...row,
        isFirstOccurrence: false,
        rowSpan: 0,
      };
    }
  });

  const colorMapping = {
    "Comunidades vegetales por conservar": "bg-grupoFuncional-conservar",
    "Comunidades vegetales por investigar": "bg-grupoFuncional-investigar",
    "Comunidades vegetales por manejar": "bg-grupoFuncional-manejar",
    "Tipo de suelo": "bg-grupoFuncional-tipoSuelo",
    "Uso de suelo": "bg-grupoFuncional-usoSuelo",
    default: "bg-grupoFuncional-default",
  };

  return (
    <div className="overflow-x-auto mt-8">
      <table className="min-w-full bg-white shadow-md rounded-lg">
        <thead>
          <tr className="bg-blueRio text-whiteRio text-sm leading-normal">
            <th className="py-3 px-6 text-left">Grupo funcional</th>
            <th className="py-3 px-6 text-left">% Área (Grupo)</th>
            <th className="py-3 px-6 text-left">Área total (ha) (Grupo)</th>
            <th className="py-3 px-6 text-left">Categorías de interés</th>
            <th className="py-3 px-6 text-left">n Polígonos</th>
            <th className="py-3 px-6 text-left">% Área</th>
            <th className="py-3 px-6 text-left">∑ Área (ha)</th>
          </tr>
        </thead>
        <tbody className="text-gray-600 text-sm">
          {preparedData.map((row, index) => (
            <tr
              key={index}
              className={`border-b border-blueRio ${
                row.isFirstOccurrence
                  ? `${colorMapping[row.grupo_funcional]}`
                  : ""
              }`}
            >
              {row.isFirstOccurrence && (
                <>
                  <td rowSpan={row.rowSpan} className="py-3 px-6 text-left">
                    {row.grupo_funcional}
                  </td>
                  <td rowSpan={row.rowSpan} className="py-3 px-6 text-left">
                    {row.percentage_area_grupo_funcional?.toFixed(2)} %
                  </td>
                  <td rowSpan={row.rowSpan} className="py-3 px-6 text-left">
                    {Math.round(row.total_area_grupo_funcional)}
                  </td>
                </>
              )}
              <td className={`py-3 px-6 text-left `}>
                {row.categorias_de_interes}
              </td>
              <td className="py-3 px-6 text-left">{row.count}</td>
              <td className="py-3 px-6 text-left">
                {row.percentage_area?.toFixed(2)}
              </td>
              <td className="py-3 px-6 text-left">
                {Math.round(row.total_area)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SueloTable;
