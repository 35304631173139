export const tableConfigurations = {
  biodiversidad: {
    headers: {
      grupo: "Grupo",
      unique_scientific_count: "Especies",
      unique_scientific_riesgo_1: "Especies en riesgo",
      unique_scientific_riesgo_0: "Especies no en riesgo",
    },
  },
  denue: {
    headers: {
      categoria: "Categoria",
      count: "Total de unidades económicas",
    },
  },
  suelo: {
    headers: {
      grupo_funcional: "Grupo funcional",
      percentage_area_grupo_funcional: "% Área (Grupo)",
      total_area_grupo_funcional: "Área total (ha) (Grupo)",
      categorias_de_interes: "Categorías de interés",
      count: "n Polígonos",
      percentage_area: "% Área",
      total_area: "∑ Área (ha)",
    },
  },
};
