export const descargasObject = [
  {
    titulo:
      "1. Regiones hidrológicas, cuenca y subcuenca del Río Santa Catarina",
    description:
      "Ubicación en la Cuenca Río Bravo - San Juan, de la subcuenca Río Monterrey RH24Bf y regiones hidrológicas que intersectan con el territorio del estado de Nuevo León (INEGI, 2010).",
    download_links: [
      { name: "riovivo_rsc_2024_1_regiones.pdf", type: "supabase" },
      { name: "riovivo_rsc_2024_1_regiones.png", type: "supabase" },
    ],
  },
  {
    titulo:
      "2. Uso de suelo y vegetación en la subcuenca del Río Santa Catarina",
    description:
      "Caracterización del territorio de la subcuenca Río Monterrey RH24Bf de acuerdo a la capa de uso de suelo y vegetación (INEGI, 2021).",
    download_links: [
      { name: "riovivo_rsc_2024_2_syv_rh24bf.pdf", type: "supabase" },
      { name: "riovivo_rsc_2024_2_syv_rh24bf.png", type: "supabase" },
    ],
  },
  {
    titulo: "3. Segmentos de la subcuenca del Río Santa Catarina",
    description:
      "Propuesta de segmentación de la subcuenca Río Monterrey RH24Bf en función al paisaje (SOSAC, 2024), Áreas Naturales Protegidas (CONABIO, Ayuntamiento de Monterrey y Ayuntamiento de Guadalupe) que intersectan con la subcuenca, líneas principales de la red hidrográfica (órdenes 5, 6 y 7; INEGI, 2010) y tramo urbano del Río Santa Catarina.",
    download_links: [
      { name: "riovivo_rsc_2024_3_segmentos_rh24bf.pdf", type: "supabase" },
      { name: "riovivo_rsc_2024_3_segmentos_rh24bf.png", type: "supabase" },
    ],
  },
  {
    titulo:
      "4. Red hidrográfica de la subcuenca del Río Santa Catarina, manzanas urbanas y huella de la construcción",
    description:
      "Red hidrográfica (INEGI, 2021), manzanas urbanas (INEGI, 2020) y huella de construcción (Pronatura Noreste, 2024) en la subcuenca Río Monterrey RH24Bf, con la delimitación de segmentos de acuerdo al paisaje (SOSAC, 2024).",
    download_links: [
      {
        name: "https://drive.google.com/uc?export=download&id=1RX7fClyR3tYHuNF6_EAiufFS9eNcWS74",
        type: "google",
      },
      { name: "riovivo_rsc_2024_4_redh_rh24bf.png", type: "supabase" },
    ],
  },
  {
    titulo:
      "5. Biodiversidad amenazada en la cuenca media del Río Santa Catarina",
    description:
      "Observaciones de biodiversidad de especies bajo alguna categoría de amenaza (NOM-059-SEMARNAT-2010 o Lista roja UICN) registradas en Naturalista (hasta junio 2024).",
    download_links: [
      {
        name: "https://drive.google.com/uc?export=download&id=1ErcKVskES9lqi2KGgbuD43m2zF8vgZzc",
        type: "google",
      },
      { name: "riovivo_rsc_2024_5_biodiversidad.png", type: "supabase" },
    ],
  },
  {
    titulo:
      "6. Acarreo hormiga de residuos en el tramo urbano del Río Santa Catarina",
    description:
      "Señalización de la ubicación de usos de suelo 'tiradero de escombro' y 'tiradero de basura' y agropecuario, también los sitios con presencia de agua, infraestructura de drenaje expuesta a lo largo del tramo urbano del Río Santa Catarina.",
    download_links: [
      { name: "riovivo_rsc_2024_6_residuos.pdf", type: "supabase" },
      { name: "riovivo_rsc_2024_6_residuos.png", type: "supabase" },
    ],
  },
  {
    titulo:
      "7. Residuos por municipio y Residuos de la construcción y demolición en el Río Santa Catarina",
    description:
      "Corte de información con enfoque municipal, con énfasis en las piezas y restos de infraestructura abandonada y construcciones sin control de afectaciones (SOSAC, 2024).",
    download_links: [
      {
        name: "https://drive.google.com/uc?export=download&id=1AoP40UFQ398BfZZurLcRnJOFucp5-o0O",
        type: "google",
      },
    ],
  },
  {
    titulo: "8. Modelo digital de elevación del Río Santa Catarina",
    description:
      "Vista completa del modelo digital de elevación construida a partir del ortomosaico y colección de cortes por municipio.",
    download_links: [
      { name: "riovivo_rsc_2024_8_mds.pdf", type: "supabase" },
      {
        name: "https://drive.google.com/uc?export=download&id=170e_CbtSv1LpKde9dik9prJowq1Wz1zn",
        type: "google",
      },
    ],
  },
  {
    titulo:
      "9. Ortomosaico de fotografías aéreas del Río Santa Catarina por municipio",
    description:
      "Colección de cortes por municipio del modelo digital de elevación construida a partir del ortomosaico de fotografías aéreas.",
    download_links: [
      {
        name: "https://drive.google.com/uc?export=download&id=1SRDdm7a3EkBy-swR9jqRD7yMXT13v_xq",
        type: "google",
      },
    ],
  },
  {
    titulo: "10. Segmentos de la subcuenca en función al paisaje",
    description:
      "Propuesta de segmentación de la subcuenca en función al paisaje a partir del polígono RH24Bf (INEGI, 2010) y las características descritas en las infografías.",
    download_links: [
      {
        name: "riovivo_rsc_2024_10_segmentos_rh24bf.geojson",
        type: "supabase",
      },
      { name: "riovivo_rsc_2024_10_segmentos_rh24bf.qmd", type: "supabase" },
    ],
  },
  {
    titulo: "11. Áreas naturales protegidas",
    description:
      "Compilación de polígonos e información asociada para las Áreas Naturales Protegidas con las que intersecta el polígono de la Subcuenca Río Monterrey RH24Bf, bajo decretos federales, estatales y municipales.",
    download_links: [
      { name: "riovivo_rsc_2024_11_anps_rh24bf.geojson", type: "supabase" },
      { name: "riovivo_rsc_2024_11_anps_rh24bf.qmd", type: "supabase" },
    ],
  },
  {
    titulo: "12. Suelo y vegetación dentro del río",
    description:
      "Tipos y usos de suelo y vegetación predominante caracterizada en el tramo urbano del Río Santa Catarina.",
    download_links: [
      { name: "riovivo_rsc_2024_12_syv_tu_rsc.geojson", type: "supabase" },
    ],
  },
  {
    titulo: "13. Buffer 1 Km alrededor del Río Santa Catarina",
    description: "Buffer de 1 Km alrededor del Río Santa Catarina.",
    download_links: [
      { name: "riovivo_rsc_2024_13_buffer.geojson", type: "supabase" },
    ],
  },
  {
    titulo:
      "14. Base de datos Naturalista (corte 10 jun 2024), grado de investigación",
    description:
      "Totalidad de registros (grado investigación, hasta el 10 de junio 2024, integrando observaciones de los lugares 203814, 203815 y 203816 en Naturalista).",
    download_links: [
      {
        name: "https://drive.google.com/uc?export=download&id=1qqUhJrS4bmnaZ2b1uLvOPf0SzqPVW-XM",
        type: "google",
      },
    ],
  },
  {
    titulo: "15. Base de datos DENUE 2024",
    description:
      "Totalidad de registros de unidades económicas (DENUE, 2024) con categorías de interés.",
    download_links: [
      { name: "riovivo_rsc_2024_15_denue.geojson", type: "supabase" },
    ],
  },
  {
    titulo:
      "16. Infografías cuenca en segmentos RSC y enfoques de restauración CONABIO",
    description:
      "Productos de divulgación sobre la Subcuenca Río Monterrey RH24Bf.",
    download_links: [
      { name: "riovivo_rsc_2024_16_infografias.pdf", type: "supabase" },
    ],
  },
  {
    titulo:
      "17. Descripción detallada de categorías de suelo y vegetación caracterizadas al interior del río",
    description:
      "Listado con descripción detallada de las categorías identificadas de tipos y usos de suelo y vegetación presentes al interior del Río Santa Catarina en su tramo urbano.",
    download_links: [
      { name: "riovivo_rsc_2024_17_syv_cats.pdf", type: "supabase" },
    ],
  },
  {
    titulo: "18. Objetos de conservación",
    description:
      "Listado de propuesta de objetos de conservación por considerar para el cuidado y regeneración del Río Santa Catarina; propuesta SOSAC 2024.",
    download_links: [
      { name: "riovivo_rsc_2024_18_objetos_con.pdf", type: "supabase" },
    ],
  },
  {
    titulo:
      "19. Reporte técnico sobre modelo de inundaciones asociadas al Río Santa Catarina 2023",
    description:
      "Reporte técnico sobre modelo de inundaciones asociadas al Río Santa Catarina empleado como justificación para la deforestación al interior del río en julio del 2023.",
    download_links: [
      { name: "riovivo_rsc_2024_19_modelo_inund.pdf", type: "supabase" },
    ],
  },
  {
    titulo: "20. Video de presentación de resultados",
    description:
      "Video del evento del primer aniversario de la Red Río Vivo, donde se expusieron los resultados del proyecto de investigación acción para el Río Santa Catarina, el 13 de junio del 2024.",
    download_links: [
      "https://www.youtube.com/live/LajMWT9ioDY?si=Gv5MnsfZm6mCRONa&t=994",
    ],
  },
  {
    titulo:
      "21. Lugar en Naturalista “RH24Bf_a”, cuenca alta de la subcuenca del Río Santa Catarina",
    description:
      "Observaciones correspondientes al lugar 203815 en Naturalista (cuenca alta de la cuenca del Río Santa Catarina).",
    download_links: [
      "https://mexico.inaturalist.org/observations?place_id=203815",
    ],
  },
  {
    titulo:
      "22. Lugar en Naturalista “RH24Bf_m”, cuenca media de la subcuenca del Río Santa Catarina",
    description:
      "Observaciones correspondientes al lugar 203814 en Naturalista (cuenca media de la cuenca del Río Santa Catarina).",
    download_links: [
      "https://mexico.inaturalist.org/observations?place_id=203814",
    ],
  },
  {
    titulo:
      "23. Lugar en Naturalista “RH24Bf_b”, cuenca baja de la subcuenca del Río Santa Catarina",
    description:
      "Observaciones correspondientes al lugar 203816 en Naturalista (cuenca baja de la cuenca del Río Santa Catarina).",
    download_links: [
      "https://mexico.inaturalist.org/observations?place_id=203816",
    ],
  },
];
