import React, { useEffect, useState } from "react";
import { supabaseClient } from "../../../config/supabase";

const ImageCarousel = ({ images }) => {
  const [imageUrls, setImageUrls] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchImageUrls = async () => {
    const urls = [];

    for (const image of images) {
      const { data, error } = supabaseClient.storage
        .from("images_carousel")
        .getPublicUrl(image);
      if (!error) {
        urls.push(data.publicUrl);
      } else {
        console.error(`Error fetching URL for ${image}:`, error);
      }
    }
    setImageUrls(urls);
    setLoading(false);
  };

  useEffect(() => {
    fetchImageUrls();
  }, [images]);

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % imageUrls.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? imageUrls.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="relative w-full h-full flex items-center justify-center">
      {loading ? (
        <div className="text-blueRio">Loading images...</div>
      ) : (
        <>
          <div
            className="absolute top-1/2 left-0 transform -translate-y-1/2 z-10 cursor-pointer"
            onClick={handlePrev}
          >
            <span className="text-blueRio text-3xl">&lt;</span>{" "}
          </div>
          {imageUrls.length > 0 && (
            <img
              src={imageUrls[currentIndex]}
              alt={`Slide ${currentIndex + 1}`}
              className="h-full object-contain rounded-lg"
              loading="lazy"
            />
          )}
          <div
            className="absolute top-1/2 right-0 transform -translate-y-1/2 z-10 cursor-pointer"
            onClick={handleNext}
          >
            <span className="text-blueRio text-3xl">&gt;</span>{" "}
          </div>
          <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 z-10">
            <span className="text-blueRio">
              {currentIndex + 1} / {imageUrls.length}
            </span>
          </div>
        </>
      )}
    </div>
  );
};

export default ImageCarousel;
