import { filteredMunicipiosByCuenca } from "./helpers";

export function getFilteredMunicipios(cuenca) {
  return filteredMunicipiosByCuenca(cuenca);
}

export function getRandomMunicipio(municipios) {
  if (municipios.length > 0) {
    return municipios[Math.floor(Math.random() * municipios.length)];
  }
  return null;
}
