import React, { useState, useEffect } from "react";
import { tabsRio } from "../../helpers/tabsHelper";
import ImageCarousel from "./ImageCarousel/ImageCarousel";

export default function ProjectDetailsSection() {
  const [tabValue, setTabValue] = useState(0);
  const [tabContent, setTabContent] = useState(null);
  const [currentImages, setCurrentImages] = useState([]);

  const handleChange = (newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    let currentTabContent = tabsRio.find((tab) => tab.id === tabValue);
    setTabContent(currentTabContent?.content);

    if (currentTabContent?.images) {
      setCurrentImages(currentTabContent.images);
    } else {
      setCurrentImages([]);
    }
  }, [tabValue]);

  return (
    <div className="max-w-full overflow-hidden p-4 m-6 box-border">
      <div className="flex overflow-x-auto justify-around">
        {tabsRio.map((tab, index) => (
          <button
            key={index}
            className={`py-2 px-4 text-lg transition-all ${
              tabValue === index
                ? "border-b-2 border-blueRio text-blueRio"
                : "text-gray-500 hover:text-blue-400"
            }`}
            onClick={() => handleChange(index)}
            id={`tab-${index}`}
            aria-controls={`tabpanel-${index}`}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <div className="flex flex-col md:flex-row mt-6 gap-6 h-full min-h-[300px]">
        <div className="basis-2/4 flex items-center justify-center min-h-[300px] h-[400px] bg-white rounded-lg">
          {currentImages.length > 0 && <ImageCarousel images={currentImages} />}
        </div>

        <div className="basis-2/4 bg-white text-blueRio px-4 py-6 min-h-[300px]  overflow-y-auto rounded-lg">
          {tabContent && (
            <div className="flex items-center justify-center h-full overflow-y-auto">
              {tabContent}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
