import React, { useRef, useEffect } from "react";
import { GeoJSON, LayersControl } from "react-leaflet";
import { useGetPolygon } from "../../../hooks/useGetPolygon";
import { getColorForPolygon } from "../helpers/colorUtils";

const LayerOverlay = ({ polygonName, name, checked, filters }) => {
  const polygonRef = useRef(null);

  const polygon = useGetPolygon(polygonName);

  const onEachFeature = (feature, layer) => {
    const color = getColorForPolygon(polygonName, feature);
    layer.setStyle({ color });
  };

  useEffect(() => {
    const layer = polygonRef.current;
    if (layer) {
      if (polygon) {
        layer.clearLayers().addData(polygon);
      }
    }
  }, [polygon]);

  return (
    <LayersControl.Overlay name={name} checked={checked ? true : false}>
      <GeoJSON data={polygon} ref={polygonRef} onEachFeature={onEachFeature} />
    </LayersControl.Overlay>
  );
};

export default LayerOverlay;
