export const puntos = [
  {
    name: "Biodiversidad",
    value: "biodiversidad",
    icon: (isSelected) => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="70"
        height="70"
        viewBox="0 0 85.05 68.37"
        className={`${
          isSelected ? "fill-white" : "fill-blueRio"
        } transition-colors duration-300 cursor-pointer`}
      >
        <path d="M84.6,23.85c-1.14-.51-2.59-1.16-3.97-1.79-.51-2.33-2.53-4.14-4.98-4.3-2.75-.18-5.09,1.67-5.69,4.19-.03,.14-14.89,14.46-14.89,14.46l-8.33,7.9c-.64,.6-.06,1.66,.79,1.45l5.19-1.29c2.71-.67,5.48-1.01,8.27-1.01,.48,.24,.99,.45,1.51,.63l-.07,3.99c-1.32-.03-2.41-.04-2.69-.04h0c-.38,0-.69,.31-.69,.69,0,.38,.3,.7,.69,.7,2.87,.03,7.43,.13,8.23,.29,.63,.12,.82,.34,.85,.38,.12,.25,.37,.4,.63,.4,.08,0,.16-.01,.24-.04,.36-.13,.55-.53,.42-.89-.05-.15-.4-.92-1.86-1.21-.59-.12-2.52-.19-4.4-.24l.06-3.64c1.02,.21,2.1,.29,3.2,.21,5.92-.41,10.64-5.31,10.84-11.24,.03-.76-.02-1.51-.13-2.23-.24-1.52,.31-3.03,1.39-4.13,.21-.21,.39-.44,.56-.68l4.72-1.11c.71-.17,.81-1.15,.14-1.44Z" />
        <path d="M32.93,32.96h3.73v21.14h3V22.51h2.34c9.47,0,17.17-7.7,17.17-17.17V0h-5.34c-9.47,0-17.17,7.7-17.17,17.17v1.05c-2.88-4.65-8.01-7.76-13.87-7.76h-6.18v6.18c0,9,7.32,16.33,16.33,16.33Zm6.73-15.79c0-7.81,6.36-14.17,14.17-14.17h2.34v2.34c0,7.81-6.36,14.17-14.17,14.17h-2.34v-2.34Zm-20.06-3.72h3.18c7.35,0,13.33,5.98,13.33,13.33v3.18h-3.18c-7.35,0-13.33-5.98-13.33-13.33v-3.18Z" />
        <path d="M49.21,60.63l-.1,.1c-3,3-6.98,4.64-11.21,4.64s-8.22-1.65-11.21-4.64l-.18-.17h0c-7.37-7.18-19.2-7.12-26.49,.17l2.12,2.12c6.18-6.18,16.24-6.18,22.43,0l.18,.17h0c3.54,3.45,8.2,5.35,13.16,5.35s9.69-1.92,13.24-5.42l.1-.1c6.18-6.18,16.25-6.18,22.43,0l2.12-2.12c-7.32-7.32-19.21-7.35-26.57-.1Z" />
      </svg>
    ),
  },
  {
    name: "DENUE",
    value: "denue",
    icon: (isSelected) => (
      <svg
        width="70"
        height="70"
        viewBox="0 0 76.92 62.18"
        xmlns="http://www.w3.org/2000/svg"
        className={`${
          isSelected ? "fill-white" : "fill-blueRio"
        } transition-colors duration-300 cursor-pointer`}
      >
        <polygon points="51.72 11.39 51.72 0 27.38 11.36 27.38 0 0 12.76 0 47.27 13.06 47.27 13.06 44.27 3 44.27 3 14.67 24.38 4.71 24.38 16.07 48.72 4.71 48.72 16.04 73.92 4.68 73.92 44.27 64.59 44.27 64.59 47.27 76.92 47.27 76.92 .03 51.72 11.39" />
        <polygon points="54.31 50.3 23.63 50.3 23.63 43.65 20.63 43.65 20.63 62.18 57.31 62.18 57.31 43.65 54.31 43.65 54.31 50.3" />
        <path d="M48.24,36.51v-12.4h-8.03v12.4c0,2.21,1.8,4.01,4.01,4.01s4.01-1.8,4.01-4.01Z" />
        <path d="M27.19,36.58v-12.47h-4.56l-3.46,12.62c.08,2.14,1.85,3.86,4.01,3.86s4.01-1.8,4.01-4.01Z" />
        <path d="M37.72,36.51v-12.4h-8.03v12.4c0,2.21,1.8,4.01,4.01,4.01s4.01-1.8,4.01-4.01Z" />
        <path d="M50.74,24.11v12.4c0,2.21,1.8,4.01,4.01,4.01s3.93-1.72,4.01-3.85l-3.46-12.56h-4.56Z" />
      </svg>
    ),
  },
  {
    name: "Suelo y Vegetación",
    value: "suelo",
    icon: (isSelected) => (
      <svg
        width="70"
        height="70"
        viewBox="0 0 83.06 69.33"
        xmlns="http://www.w3.org/2000/svg"
        className={`transition-colors duration-300 cursor-pointer ${
          isSelected ? "fill-white" : "fill-blueRio"
        }`}
      >
        <g>
          <polygon points="3.03 40.78 .03 40.78 .03 33.28 7.53 33.28 7.53 36.28 3.03 36.28 3.03 40.78" />
          <polygon points="7.53 69.33 .03 69.33 .03 61.83 3.03 61.83 3.03 66.33 7.53 66.33 7.53 69.33" />
          <path d="M66.39,69.33h-11v-3h11v3Zm-21,0h-11v-3h11v3Zm-21,0H13.39v-3h11v3Z" />
          <g>
            <rect x="12.69" y="33.21" width="11" height="3" />
            <rect x="33.69" y="33.21" width="11" height="3" />
          </g>
        </g>
        <path d="M58.18,53.21l1.31-9.72c.09-.63-.81-.94-1.19-.41l-6.49,9.11c-.35,.49-1.18,.27-1.19-.32l-.19-9.98c-.01-.62-.9-.82-1.22-.28l-5.91,10.19c-.27,.47-1.02,.4-1.19-.11l-3.73-11.25c-.19-.57-1.05-.57-1.24,0l-3.72,11.23c-.17,.51-.92,.58-1.19,.11l-5.89-10.17c-.32-.55-1.21-.35-1.22,.28l-.19,9.98c-.01,.59-.84,.82-1.19,.32l-6.49-9.11c-.38-.53-1.27-.22-1.19,.41l1.31,9.72c.08,.57-.67,.91-1.1,.5l-7.42-6.89c-.47-.44-1.26,0-1.09,.59l3.84,12.92c.08,.26,.33,.44,.63,.44H63.32c.29,0,.55-.18,.63-.44l3.84-12.92c.18-.6-.61-1.03-1.09-.59l-7.42,6.89c-.44,.41-1.18,.07-1.1-.5Z" />
        <g>
          <path d="M63.58,0c-10.74,0-19.48,8.74-19.48,19.48s8.74,19.48,19.48,19.48,19.48-8.74,19.48-19.48S74.32,0,63.58,0Zm0,35.96c-9.09,0-16.48-7.39-16.48-16.48S54.49,3,63.58,3s16.48,7.39,16.48,16.48-7.39,16.48-16.48,16.48Z" />
          <polygon points="75.56 20.89 73.44 18.77 65.08 27.13 65.08 14.74 62.08 14.74 62.08 27.13 53.71 18.77 51.59 20.89 62.08 31.38 62.08 46.83 65.08 46.83 65.08 31.38 75.56 20.89" />
        </g>
        <rect x="0" y="46.81" width="3" height="8.85" />
      </svg>
    ),
  },
];
