import React, { useRef, useState, useEffect, useContext } from "react";
import { MapContainer, TileLayer, LayersControl } from "react-leaflet";
import LayerOverlay from "./LayerOverlay/LayerOverlay";
import PointsLayerOverlay from "./PointsLayerOverlay/PointsLayerOverlay";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { FiltersContext } from "../../contexts/FiltersContext";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const Map = () => {
  const mapRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const { filters } = useContext(FiltersContext);
  const latitude = 25.66328;
  const longitude = -100.31511;

  const alcaldiaBounds = {
    SC: { center: [25.66291588338259, -100.44118223567087], zoom: 16 },
    SPGG: { center: [25.669524974990523, -100.38032512287823], zoom: 16 },
    MTY: { center: [25.663096248273725, -100.31115812471367], zoom: 16 },
    GPE: { center: [25.68370054746102, -100.25957249782097], zoom: 16 },
  };

  useEffect(() => {
    if (mapRef.current && filters.municipio) {
      const map = mapRef.current;
      const { center, zoom } = alcaldiaBounds[filters.municipio] || {};

      if (center && zoom) {
        map.flyTo(center, zoom, {
          duration: 1.5,
        });
      }
    }
  }, [filters.municipio]);

  return (
    <div className="relative w-full h-screen">
      <MapContainer
        center={[latitude, longitude]}
        zoom={13}
        ref={mapRef}
        className="w-full h-5/6 border-2 border-blueRio mt-4 p-4 "
        scrollWheelZoom={true} //
        touchZoom={true}
        zoomControl={true}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url=" https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
        />
        <LayersControl position="topright">
          <LayerOverlay
            polygonName="tramo_urbano"
            name="Tramo urbano"
            checked
          />
          <LayerOverlay
            polygonName="buffer_1km_tu"
            name="Buffer 1km - Tramo Urbano"
          />
          <LayerOverlay
            polygonName="tres_segmentos"
            name="Tres segmentos"
            color="purple"
          />
          <LayerOverlay
            polygonName="red_hidrografica_orden_6_7"
            name="Red Hidrográfica orden 6 y 7"
          />
          <LayerOverlay
            polygonName="red_hidrografica_orden_5_7"
            name="Red Hidrográfica orden 5 y 7"
          />
          <LayerOverlay
            polygonName="anps_rh24Bf"
            name="Áreas naturales protegidas"
          />
        </LayersControl>
        <PointsLayerOverlay setLoading={setLoading} />
      </MapContainer>
    </div>
  );
};

export default Map;
