import { useState, useEffect } from "react";
import { supabaseClient } from "../config/supabase";
import { getParamsForRpc } from "../helpers/helpers";

export function useGetPolygonParameter(filters, rpcFunctionName) {
  const [polygon, setPolygon] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);

  async function getPolygonDynamic() {
    setLoading(true);
    setError(null);
    setPolygon(null);

    const params = getParamsForRpc(rpcFunctionName, filters);
    let { data, error } = await supabaseClient.rpc(rpcFunctionName, params);
    if (error) {
      setError(error);
      setLoading(false);
    } else {
      setPolygon(data?.features || []);
      setLoading(false);
    }
  }

  useEffect(() => {
    // getPolygon();
    getPolygonDynamic();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, rpcFunctionName]);

  return { polygon, error, loading };
}
