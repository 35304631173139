import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faFile } from "@fortawesome/free-solid-svg-icons";
import { descargasObject } from "./descargasUtils";
import { supabaseClient } from "../../config/supabase";

const Descargas = () => {
  const [fileUrls, setFileUrls] = useState({});

  useEffect(() => {
    const fetchUrls = async () => {
      const urls = {};

      for (const item of descargasObject) {
        for (const link of item.download_links) {
          const linkName = typeof link === "string" ? link : link.name;
          const linkType = typeof link === "object" ? link.type : "supabase";

          if (linkType === "supabase") {
            // Ir por el URL de descarga de Supabase
            const { data, error } = supabaseClient.storage
              .from("descargas_files")
              .getPublicUrl(linkName);
            if (error) {
              console.error(`Error fetching URL for ${linkName}:`, error);
            } else {
              urls[linkName] = data.publicUrl;
            }
          } else if (linkType === "google") {
            urls[linkName] = linkName;
          }
        }
      }

      setFileUrls(urls); // Actualiza el estado con los urls de supabase
    };

    fetchUrls();
  }, []);

  return (
    <div className="container mx-auto mt-4">
      <table className="min-w-full bg-white">
        <thead>
          <tr className="bg-blueRio text-whiteRio text-sm leading-normal">
            <th className="py-3 px-6 text-left">Nombre</th>
            <th className="py-3 px-6 text-left">Descripción</th>
            <th className="py-3 px-6 text-left">Enlaces de Descarga</th>
          </tr>
        </thead>
        <tbody>
          {descargasObject.map((item, index) => (
            <tr key={index} className="border border-blueRio">
              <td className="py-2 px-4">{item.titulo}</td>
              <td className="py-2 px-4">{item.description}</td>
              <td className="py-2 px-4">
                {item.download_links.map((link, linkIndex) => {
                  const linkName = typeof link === "string" ? link : link.name;
                  const linkType =
                    typeof link === "object" ? link.type : "supabase"; // Default supabase sí es string

                  return (
                    <div key={linkIndex} className="flex items-center">
                      {linkType === "supabase" || linkName.endsWith(".pdf") ? (
                        <FontAwesomeIcon
                          icon={faFilePdf}
                          className="mr-2 text-red-500"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faFile}
                          className="mr-2 text-blue-500"
                        />
                      )}
                      <a
                        href={fileUrls[linkName] || linkName}
                        className="text-blue-500 hover:underline"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {linkName}
                      </a>
                    </div>
                  );
                })}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Descargas;
