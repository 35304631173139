import React, { useRef, useState, useEffect, useContext } from "react";
import "./PointsLayerOverlay.css";
import { GeoJSON } from "react-leaflet";
import { useGetPolygonParameter } from "../../../hooks/useGetPolygonParameter.js";
import { FiltersContext } from "../../../contexts/FiltersContext.js";
import { createPointToLayer } from "../helpers/colorUtils.js";

const PointsLayerOverlay = ({ setLoading }) => {
  const polygonRef = useRef(null);
  const { filters } = useContext(FiltersContext);

  const rpcName = (() => {
    switch (filters.puntos) {
      case "biodiversidad":
        return "get_biodiversidad_points";
      case "suelo":
        return "get_suelo_y_vegetacion";
      case "denue":
        return "get_denue_points";
      default:
        return "get_biodiversidad_points";
    }
  })();

  const polygonStyle = (feature) => {
    const { gpo_fx } = feature.properties;

    let fillColor;
    switch (gpo_fx) {
      case "Comunidades vegetales por conservar":
        fillColor = "#f4a179";
        break;
      case "Comunidades vegetales por investigar":
        fillColor = "#f4e181";
        break;
      case "Comunidades vegetales por conservar":
        fillColor = "#a1d183";
        break;
      case "Tipo de suelo":
        fillColor = "#7ccdee";
        break;
      case "Uso de suelo":
        fillColor = "#c4b4a9";
        break;
      default:
        fillColor = "#cccccc";
    }

    return {
      color: "#333",
      weight: 0.5,
      fillColor: fillColor,
      fillOpacity: 0.8,
    };
  };

  const { polygon, loading } = useGetPolygonParameter(filters, rpcName);

  const onPointClick = () => {};

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  useEffect(() => {
    const layer = polygonRef.current;
    if (layer) {
      layer.clearLayers();
      if (polygon) {
        layer.addData(polygon);
      }
    }
  }, [polygon]);

  return (
    polygon && (
      <GeoJSON
        data={polygon}
        ref={polygonRef}
        onclick={onPointClick}
        style={polygonStyle}
        pointToLayer={createPointToLayer(rpcName)}
        key={rpcName}
      />
    )
  );
};

export default PointsLayerOverlay;
