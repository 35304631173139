import { useContext, useState, useEffect } from "react";
import { FiltersContext } from "../../contexts/FiltersContext";
import {
  getFilteredMunicipios,
  getRandomMunicipio,
} from "../../helpers/municipiosHelper";
import { puntos } from "./filtersUtils";

export default function Filters() {
  const { filters, setFilters } = useContext(FiltersContext);
  const [municipios, setMunicipios] = useState([]);

  const showBuffer = filters.puntos === "biodiversidad";
  const showCuenca =
    filters.puntos === "biodiversidad" || filters.puntos === "denue";

  function municipioUpdateHandler(e) {
    setFilters({
      ...filters,
      municipio: e.target.value,
    });
  }

  function cuencaUpdateHandler(e) {
    setFilters({
      ...filters,
      cuenca: e.target.value,
    });
  }

  function tramoUpdateHandler(e) {
    setFilters({
      ...filters,
      rh: e.target.value === "buffer" ? 1 : 0,
    });
  }

  const puntosUpdateHandler = (newValue) => {
    if (newValue === "suelo") {
      const filteredMunicipios = getFilteredMunicipios("media");
      setMunicipios(filteredMunicipios);

      const randomMunicipio = getRandomMunicipio(filteredMunicipios);
      setFilters({
        ...filters,
        puntos: newValue,
        cuenca: "media",
        municipio: randomMunicipio ? randomMunicipio.clave : "",
      });
    } else {
      setFilters({
        ...filters,
        puntos: newValue,
      });
    }
  };

  useEffect(() => {
    const filteredMunicipios = getFilteredMunicipios(filters.cuenca);
    setMunicipios(filteredMunicipios);

    const randomMunicipio = getRandomMunicipio(filteredMunicipios);
    if (randomMunicipio) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        municipio: randomMunicipio.clave,
      }));
    }
  }, [filters.cuenca]);

  return (
    <div className="flex flex-col md:flex-row justify-around items-center	max-w-full p-1 md:p-4 md:m-6 gap-4 box-border">
      <div className="flex flex-row justify-between gap-3">
        {puntos.map((punto, index) => (
          <button
            key={index}
            className={`flex flex-col items-center py-2 px-4 text-lg transition-all w-32  rounded-md ${
              filters.puntos === punto.value
                ? "border-b-2 bg-blueRio text-white"
                : " hover:bg-hoverBlue"
            }`}
            onClick={() => puntosUpdateHandler(punto.value)}
            id={`tab-${index}`}
            aria-controls={`tabpanel-${index}`}
          >
            {punto.icon ? punto.icon(filters.puntos === punto.value) : null}
            {punto.name}
          </button>
        ))}
      </div>
      <div className="flex flex-row justify-between items-center gap-3 ">
        <div className="flex flex-col items-center">
          <label
            htmlFor="municipios"
            className="block mb-2 text-lg font-medium text-blueRio whitespace-nowrap"
          >
            Municipio
          </label>
          <select
            id="municipios"
            className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blueRio focus:border-blueRio"
            value={filters.municipio}
            onChange={municipioUpdateHandler}
          >
            {municipios.map((municipio) => (
              <option key={municipio.id} value={municipio.clave}>
                {municipio.displayName}
              </option>
            ))}
          </select>
        </div>
        {showBuffer && (
          <div className="flex flex-col items-center">
            <label
              htmlFor="tramos"
              className="block mb-2 text-lg font-medium text-blueRio whitespace-nowrap"
            >
              Buffer
            </label>
            <select
              id="tramos"
              className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blueRio focus:border-blueRio"
              value={filters.rh === 1 ? "buffer" : "no_buffer"}
              onChange={tramoUpdateHandler}
            >
              <option value="buffer">Buffer 1km - Tramo Urbano</option>
              <option value="no_buffer">Sin buffer</option>
            </select>
          </div>
        )}
        {showCuenca && (
          <div className="flex flex-col items-center">
            <label
              htmlFor="cuenca"
              className="block mb-2 text-lg font-medium text-blueRio whitespace-nowrap"
            >
              Sección de Cuenca
            </label>
            <select
              id="cuenca"
              className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blueRio focus:border-blueRio"
              value={filters.cuenca}
              onChange={cuencaUpdateHandler}
            >
              <option value="alta">Cuenca Alta</option>
              <option value="media">Cuenca Media</option>
              <option value="baja">Cuenca Baja</option>
            </select>
          </div>
        )}
      </div>
    </div>
  );
}
