import React, { useContext } from "react";
import { FiltersContext } from "../../contexts/FiltersContext";
import useFilteredTableData from "./useFilteredTableData";
import { tableConfigurations } from "./tableConfig";
import SueloTable from "./SueloTable";

const Table = ({ version, rpcName }) => {
  const { filters } = useContext(FiltersContext);
  const { data, loading, error } = useFilteredTableData(filters, rpcName);

  const tableConfig = tableConfigurations[version];

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  if (!data || data.length === 0) {
    return <div>No data available</div>;
  }

  if (version === "suelo") {
    return <SueloTable data={data} />;
  }

  const tableHeaders = Object.values(tableConfig.headers);
  const dataKeys = Object.keys(tableConfig.headers);

  return (
    <div className="overflow-x-auto mt-2">
      <table className="min-w-full bg-white shadow-md rounded-lg">
        <thead>
          <tr className="bg-blueRio text-whiteRio text-sm leading-normal">
            {tableHeaders.map((header, index) => (
              <th key={index} className="py-3 px-6 text-left">
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="text-blackRio text-sm">
          {data.map((item, index) => (
            <tr
              key={index}
              className="border-b border-blueRio hover:bg-gray-100"
            >
              {dataKeys.map((key, colIndex) => (
                <td key={colIndex} id={key} className="py-3 px-6 text-left">
                  {item[key]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
